import { createSelector } from '@ngrx/store';
import { FinanceManagerState } from '../../finance-manager.state';
import { EconSettlementFile, JBankSettlementFile } from '../../models/partner.model';
import { PartnerCompanyBankFilesByWeekVM } from '../../models/partner.vm.model';
import { Area } from '../../../shared/models/area.model';
import { AuthSelectors } from '../../../auth';

export const areasSelector = createSelector(AuthSelectors.areaListSelector, areas => areas);

export const partnerSettlementsState = (state: FinanceManagerState) => state.partnerSettlements;

export const isLoadingSelector = createSelector(partnerSettlementsState, state => state.isLoading);

export const jBankFilesSelector = createSelector(partnerSettlementsState, state => state.jBankSettlementFiles);

export const econFilesSelector = createSelector(partnerSettlementsState, state => state.econSettlementFiles);

export const settlementListSelector = createSelector(
  jBankFilesSelector,
  econFilesSelector,
  areasSelector,
  (jBankSettlementFiles, econSettlementFiles, areas) =>
    areas.length > 0 && jBankSettlementFiles.length > 0 && econSettlementFiles.length > 0
      ? mapFilesToPartnerSettlements(jBankSettlementFiles, econSettlementFiles, areas)
      : [],
);

function mapFilesToPartnerSettlements(
  jBankSettlementFiles: JBankSettlementFile[],
  econSettlementFiles: EconSettlementFile[],
  areas: Area[],
): PartnerCompanyBankFilesByWeekVM[] {
  const mergedArray = mergeArraysByAreaIdAndLocalWeek(jBankSettlementFiles, econSettlementFiles);
  return mergedArray
    .map(array => ({
      week: 'Week ' + array.local_week.toString().slice(-2),
      local_week: array.local_week,
      area: areas.find(area => area.id === array.area_id)?.name,
      jBankSettlementFileName: array.jBankSettlementFileName,
      econSettlementFileName: array.econSettlementFileName,
    }))
    .sort((a, b) => (a.local_week > b.local_week ? -1 : 1));
}

function mergeArraysByAreaIdAndLocalWeek(arr1: JBankSettlementFile[], arr2: EconSettlementFile[]) {
  const mergedObject: {
    [key: string]: {
      area_id: string;
      local_week: number;
      jBankSettlementFileName?: string;
      econSettlementFileName?: string;
    };
  } = {};

  const mergeItem = (item: JBankSettlementFile | EconSettlementFile, isFromArr1: boolean) => {
    const key = `${item.area_id}-${item.local_week}`;

    if (!mergedObject[key]) {
      mergedObject[key] = { area_id: item.area_id, local_week: item.local_week };
    }

    if (isFromArr1) {
      mergedObject[key].jBankSettlementFileName = item.file_name;
    } else {
      mergedObject[key].econSettlementFileName = item.file_name;
    }
  };

  arr1.forEach(item => mergeItem(item, true));
  arr2.forEach(item => mergeItem(item, false));

  return Object.values(mergedObject);
}
