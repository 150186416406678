import { Action, createReducer, on } from '@ngrx/store';
import { PageState } from '../../../shared/models/elastic.vm.model';
import { SearchResponse } from '../../../shared/utils/elasticsearch/elastic-api.model';
import { PartnerCompanySearchResponse } from '../../models/partner-company.vm.model';
import { PartnerCompaniesActions } from './index';
import { PartnerCompany } from '../../models/partner-company.model';
import { Params } from '@angular/router';
import { Area } from '../../../shared/models/area.model';
import { PartnerSettlement } from '../../../shared/models/partner-company.model';

export interface PartnerCompaniesState {
  isLoading: boolean;
  queryParams: Params;
  areas: Area[];
  partnerCompanySearchResponse: SearchResponse<PartnerCompanySearchResponse>;
  partnerCompanyListPageState: PageState;
  partnerCompany: PartnerCompany;
  partnerSettlements: PartnerSettlement[];
}

export const initialState: PartnerCompaniesState = {
  isLoading: false,
  queryParams: null,
  areas: [],
  partnerCompanySearchResponse: null,
  partnerCompanyListPageState: {
    previousPageIndex: 0,
    pageIndex: 0,
    pageSize: 10,
  } as PageState,
  partnerCompany: null,
  partnerSettlements: [],
};

export const reducer = createReducer(
  initialState,
  on(PartnerCompaniesActions.queryParamsChanged, (state, { queryParams }) => ({ ...state, queryParams })),
  on(PartnerCompaniesActions.areasFetched, (state, { areas }) => ({ ...state, areas })),
  on(PartnerCompaniesActions.queryParamsChanged, (state, _) => ({ ...state, isLoading: true })),
  on(PartnerCompaniesActions.searchResponseFetched, (state, action) => ({
    ...state,
    isLoading: false,
    partnerCompanySearchResponse: action.searchResponse,
  })),
  on(PartnerCompaniesActions.pageChanged, (state, { pageEvent }) => ({
    ...state,
    partnerCompanyListPageEvent: pageEvent,
  })),
  on(PartnerCompaniesActions.partnerCompanyDetailsInitiated, (state, _) => ({ ...state, isLoading: true })),
  on(PartnerCompaniesActions.partnerCompanyDetailsFetched, (state, { partnerCompany }) => ({
    ...state,
    partnerCompany: partnerCompany,
    isLoading: false,
  })),
  on(PartnerCompaniesActions.partnerCompanySettlementsFetched, (state, { settlements }) => ({
    ...state,
    partnerSettlements: settlements,
  })),
  on(PartnerCompaniesActions.partnerSettlementListDestroyed, (state, _) => ({
    ...state,
    partnerSettlements: [],
  })),
  on(PartnerCompaniesActions.creditNoteFileDownloadClicked, (state, _) => ({ ...state, isLoading: true })),
  on(PartnerCompaniesActions.creditNoteFileDownloaded, (state, _) => ({ ...state, isLoading: false })),
);

export function partnerCompaniesState(state: PartnerCompaniesState, action: Action) {
  return reducer(state, action);
}
