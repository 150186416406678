import { PartnerCompanyListVM, PartnerCompanySearchResponse } from '../models/partner-company.vm.model';

export function mapSearchResponseToPartnerCompanyList(
  response: PartnerCompanySearchResponse[],
): PartnerCompanyListVM[] {
  return response?.map(item => ({
    id: item.id,
    name: item.company.name,
    registrationNumber: item.company.registration_number,
  }));
}
