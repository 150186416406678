import { createSelector } from '@ngrx/store';
import { PartnerCompaniesState } from './partner-companies.state';
import { FinanceManagerState } from '../../finance-manager.state';
import { mapSearchResponseToPartnerCompanyList } from '../../mappings/partner-companies.mappings';
import { mapQueryParamsToSearchForm } from '../../../shared/mappings/url-params.mappings';
import { Area } from '../../../shared/models/area.model';
import { userAreaIdsSelector } from '../../../auth/auth.selectors';
import { matPartnerSettlementsToPartnerSettlementsVM } from '../../../shared/mappings/partner.mappings';

export const selectPartnerCompanyListState = (state: FinanceManagerState) => state.partnerCompanies;

export const selectIsLoading = createSelector(
  selectPartnerCompanyListState,
  (state: PartnerCompaniesState) => state.isLoading,
);

export const selectQueryParams = createSelector(
  selectPartnerCompanyListState,
  (state: PartnerCompaniesState) => state.queryParams,
);

export const selectAreas = createSelector(selectPartnerCompanyListState, (state: PartnerCompaniesState) => state.areas);

export const selectPartnerCompanyListPageEvent = createSelector(
  selectPartnerCompanyListState,
  (state: PartnerCompaniesState) => state.partnerCompanyListPageState,
);

export const selectPartnerCompanySearchResponse = createSelector(
  selectPartnerCompanyListState,
  (state: PartnerCompaniesState) => state.partnerCompanySearchResponse,
);

export const selectPartnerCompanyList = createSelector(selectPartnerCompanySearchResponse, response =>
  response?.hits?.hits?.length > 0
    ? mapSearchResponseToPartnerCompanyList(response.hits.hits.map(hit => hit._source))
    : [],
);

export const selectPartnerCompanyName = createSelector(selectPartnerCompanyListState, (state: PartnerCompaniesState) =>
  state.partnerCompany ? state.partnerCompany.name : 'Loading',
);

export const selectPartnerCompanySettlementListVM = createSelector(
  selectPartnerCompanyListState,
  selectAreas,
  (state: PartnerCompaniesState, areas: Area[]) =>
    state.partnerSettlements.length > 0 && areas.length > 0
      ? matPartnerSettlementsToPartnerSettlementsVM(state.partnerSettlements, areas)
      : [],
);

export const selectPartnerCompanyListSearchForm = createSelector(
  selectQueryParams,
  userAreaIdsSelector,
  (queryParams, areaIds) => mapQueryParamsToSearchForm(queryParams, areaIds),
);
