import { createActionGroup, props } from '@ngrx/store';
import { EconSettlementFile, JBankSettlementFile } from '../../models/partner.model';

const partnerSettlementsActions = createActionGroup({
  source: 'Partner Settlements',
  events: {
    listComponentInit: null,
    jBankSettlementFileChanged: props<{ JBankSettlementFiles: JBankSettlementFile[] }>(),
    econSettlementFileChanged: props<{ EconSettlementFiles: EconSettlementFile[] }>(),
    jBankSettlementFileDownloadClicked: props<{ fileName: string }>(),
    jBankSettlementFileDownloaded: null,
    econSettlementFileDownloadClicked: props<{ fileName: string }>(),
    econSettlementFileDownloaded: null,
  },
});

export const {
  listComponentInit,
  jBankSettlementFileChanged,
  econSettlementFileChanged,
  jBankSettlementFileDownloadClicked,
  jBankSettlementFileDownloaded,
  econSettlementFileDownloadClicked,
  econSettlementFileDownloaded,
} = partnerSettlementsActions;
