import { createActionGroup, props } from '@ngrx/store';
import { SearchForm } from '../../../shared/models/elastic.vm.model';
import { PageEvent } from '@angular/material/paginator';
import { Params } from '@angular/router';
import { SearchResponse } from '../../../shared/utils/elasticsearch/elastic-api.model';
import { PartnerCompanySearchResponse } from '../../models/partner-company.vm.model';
import { PartnerCompany } from '../../models/partner-company.model';
import { Area } from '../../../shared/models/area.model';
import { PartnerSettlement } from '../../../shared/models/partner-company.model';

const partnerCompaniesActions = createActionGroup({
  source: 'Partner Company List',
  events: {
    partnerCompanyListInitiated: null,
    areasFetched: props<{ areas: Area[] }>(),
    searchSubmitButtonClicked: props<{ searchForm: SearchForm }>(),
    pageChanged: props<{ pageEvent: PageEvent }>(),
    searchResponseFetched: props<{ searchResponse: SearchResponse<PartnerCompanySearchResponse> }>(),
    queryParamsChanged: props<{ queryParams: Params }>(),
    partnerCompanyDetailsInitiated: props<{ partnerCompanyId: string }>(),
    partnerCompanyDetailsFetched: props<{ partnerCompany: PartnerCompany }>(),
    partnerCompanySettlementsFetched: props<{ settlements: PartnerSettlement[] }>(),
    partnerSettlementListDestroyed: null,
    creditNoteFileDownloadClicked: props<{ settlementId: string }>(),
    creditNoteFileDownloaded: null,
  },
});

export const {
  partnerCompanyListInitiated,
  areasFetched,
  searchSubmitButtonClicked,
  pageChanged,
  searchResponseFetched,
  queryParamsChanged,
  partnerCompanyDetailsInitiated,
  partnerCompanyDetailsFetched,
  partnerCompanySettlementsFetched,
  partnerSettlementListDestroyed,
  creditNoteFileDownloadClicked,
  creditNoteFileDownloaded,
} = partnerCompaniesActions;
