import { Action, createReducer, on } from '@ngrx/store';
import { PartnerSettlementsActions } from './index';
import { EconSettlementFile, JBankSettlementFile } from '../../models/partner.model';

export interface PartnerSettlementsState {
  isLoading: boolean;
  jBankSettlementFiles: JBankSettlementFile[];
  econSettlementFiles: EconSettlementFile[];
}

export const initialState: PartnerSettlementsState = {
  isLoading: false,
  jBankSettlementFiles: [],
  econSettlementFiles: [],
};

export const reducer = createReducer(
  initialState,
  on(PartnerSettlementsActions.jBankSettlementFileChanged, (state, action) => ({
    ...state,
    jBankSettlementFiles: action.JBankSettlementFiles,
  })),
  on(PartnerSettlementsActions.econSettlementFileChanged, (state, action) => ({
    ...state,
    econSettlementFiles: action.EconSettlementFiles,
  })),
  on(PartnerSettlementsActions.jBankSettlementFileDownloadClicked, (state, _action) => ({
    ...state,
    isLoading: true,
  })),
  on(PartnerSettlementsActions.jBankSettlementFileDownloaded, (state, _action) => ({
    ...state,
    isLoading: false,
  })),
  on(PartnerSettlementsActions.econSettlementFileDownloadClicked, (state, _action) => ({
    ...state,
    isLoading: true,
  })),
  on(PartnerSettlementsActions.econSettlementFileDownloaded, (state, _action) => ({
    ...state,
    isLoading: false,
  })),
);

export function partnerSettlementsReducer(state: PartnerSettlementsState, action: Action) {
  return reducer(state, action);
}
